import { gql } from '@lib/store/strapi-graphql-query'

import { WITH_GALLERY } from '@store/fragments/shared/gallery'
import { WITH_COMMON_SECTION } from '@store/fragments/sections/common-section'
import { WITH_DURATION } from '@store/fragments/atoms/time-selector'
import { WITH_LINKABLE_LIST } from '@store/fragments/atoms/linkable-list'
import { WITH_COLLECTION } from '@store/fragments/dashboard/collection'

import { WITH_PROJECTS_OVERVIEW } from '@store/fragments/entities/project'
import { WITH_LESSONS_OVERVIEW } from '@store/fragments/entities/lesson'
import { WITH_ACTIVITIES_OVERVIEW } from '@store/fragments/entities/activity'
import { WITH_RESOURCES_OVERVIEW } from '@store/fragments/entities/resource'

export const FULL_PAGE_QUERY = gql`
  ${WITH_LINKABLE_LIST}
  ${WITH_DURATION}

  ${WITH_GALLERY}
  ${WITH_COMMON_SECTION}

  ${WITH_COLLECTION}

  ${WITH_PROJECTS_OVERVIEW}
  ${WITH_LESSONS_OVERVIEW}
  ${WITH_ACTIVITIES_OVERVIEW}
  ${WITH_RESOURCES_OVERVIEW}

  query GetPage($slug: String!) {
    pages(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          title
          description
          slug
          workspace

          blocks {
            __typename
            ... on ComponentSharedRichText {
              id
              body
            }

            ... on ComponentSharedGallery {
              ...GALLERY
            }

            ... on ComponentSectionsCommonSection {
              ...COMMON_SECTION
            }

            ... on ComponentSharedMedia {
              file {
                ...FILE
              }
            }

            ... on ComponentDashboardCollections {
              ...COLLECTION
            }
          }
        }
      }
    }
  }
`
