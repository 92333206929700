import { createApi } from '@reduxjs/toolkit/query/react'
import protectedQuery from '@store/utils/protected-query.graphql'

import { FULL_PAGE_QUERY } from '@store/queries/page'

export const pageApiGraphql = createApi({
  reducerPath: 'pageApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getPageBySlug: builder.query({
      query: slug => ({
        document: FULL_PAGE_QUERY,
        variables: { slug },
      }),
    }),
  }),
})

export const { useGetPageBySlugQuery } = pageApiGraphql
