import { createApi } from '@reduxjs/toolkit/query/react'
import protectedQuery from '@store/utils/protected-query.graphql'

import { FULL_SECTIONS_QUERY } from '@store/queries/section'

export const sectionApiGraphql = createApi({
  reducerPath: 'sectionApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getSections: builder.query({
      query: filters => ({
        document: FULL_SECTIONS_QUERY,
        variables: { filters },
      }),
    }),

    getSection: builder.query({
      query: (id) => ({
        document: FULL_SECTIONS_QUERY,
        variables: {
          filters: { id: { eq: id } },
        }
      }),
    }),
  }),
})

export const { useGetSectionsQuery, useGetSectionQuery } = sectionApiGraphql
