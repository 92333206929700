import { gql } from '@lib/store/strapi-graphql-query'

export const WITH_LESSONS_OVERVIEW = gql`
  fragment LESSONS_OVERVIEW on LessonRelationResponseCollection {
    data {
      id
        __typename
      attributes {
        name

        preview {
          ...FILE
        }
        preparationDuration {
          ...DURATION
        }
        learningDuration {
          ...DURATION
        }
      }
    }
  }
`
