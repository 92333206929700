import { createApi } from '@reduxjs/toolkit/query/react'
import protectedQuery from '@store/utils/protected-query.graphql'

import { USER_LOGIN_MUTATION } from '@store/queries/user'

export const userApiGraphql = createApi({
  reducerPath: 'userApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        document: USER_LOGIN_MUTATION,
        variables: credentials,
      })
    })
  }),

  // todo: Register, Logout, ForgotPassword
})

export const { useLoginMutation } = userApiGraphql
