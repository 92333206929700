import { gql } from '@lib/store/strapi-graphql-query'

export const WITH_RELATED_TAGS = gql`
  fragment RELATED_TAGS on TagRelationResponseCollection {
    data {
      id
      attributes {
        name
        context
        slug
      }
    }
  }
`
