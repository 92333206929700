import { gql } from '@lib/store/strapi-graphql-query'

import { WITH_RELATED_TAGS } from '@store/fragments/tag'
import { WITH_RELATED_SUBJECTS } from '@store/fragments/subject'

import { WITH_GALLERY } from '@store/fragments/shared/gallery'
import { WITH_LEVEL } from '@store/fragments/content/level'

import { WITH_DURATION } from '@store/fragments/atoms/time-selector'
import { WITH_LINKABLE_LIST } from '@store/fragments/atoms/linkable-list'

import { WITH_PROJECTS_OVERVIEW } from '@store/fragments/entities/project'
import { WITH_LESSONS_OVERVIEW } from '@store/fragments/entities/lesson'
import { WITH_CHALLENGES_OVERVIEW } from '@store/fragments/entities/challenge'

import { WITH_COMMON_SECTION } from '@store/fragments/sections/common-section'

export const FULL_RESOURCE_QUERY = gql`
  ${WITH_RELATED_SUBJECTS}
  ${WITH_RELATED_TAGS}

  ${WITH_GALLERY}

  ${WITH_LEVEL}

  ${WITH_DURATION}
  ${WITH_LINKABLE_LIST}

  ${WITH_PROJECTS_OVERVIEW}
  ${WITH_LESSONS_OVERVIEW}
  ${WITH_CHALLENGES_OVERVIEW}

  ${WITH_COMMON_SECTION}

  query GetResource($id: ID!) {
    resource(id: $id) {
      data {
        id
        attributes {
          name
          description
          slug

          gallery {
            ...GALLERY
          }

          level {
            ...LEVEL
          }

          subjects {
            ...RELATED_SUBJECTS
          }

          tags {
            ...RELATED_TAGS
          }

          linkedItems {
            __typename
            ... on ComponentItemsLinkedProjects {
              projects {
                ...PROJECTS_OVERVIEW
              }
            }

            ... on ComponentItemsLinkedLessons {
              lessons {
                ...LESSONS_OVERVIEW
              }
            }

            ... on ComponentItemsLinkedChallenges {
              challenges {
                ...CHALLENGES_OVERVIEW
              }
            }
          }

          sections {
            __typename
            ... on ComponentSectionsCommonSection {
              ...COMMON_SECTION
            }
          }
        }
      }
    }
  }
`
