import { strapiRestQuery } from '@lib/store/strapi-rest-query'

const API_URL = process.env.GATSBY_API_URL + '/api'

export default strapiRestQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState()

    const { jwt } = auth

    if (jwt) {
      headers.set('Authorization', `Bearer ${jwt}`)
    }

    return headers
  },
})
