import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { useDispatch } from 'react-redux'

import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist'

import uiReducer from '@store/slices/ui.slice'

import navReducer from '@store/slices/nav.slice'
import authReducer from '@store/slices/auth.slice'

import { authApi } from './api/auth.api'
import { featureApi } from '@store/api/feature.api'

import { userApiGraphql } from '@store/api/user.api.graphql'
import { featureApiGraphql } from '@store/api/feature.api.graphql'
import { libraryApiGraphql } from '@store/api/steamhub/library.api.graphql'
import { projectApiGraphql } from '@store/api/steamhub/project.api.graphql'
import { lessonApiGraphql } from '@store/api/steamhub/lesson.api.graphql'
import { activityApiGraphql } from '@store/api/steamhub/activity.api.graphql'
import { resourceApiGraphql } from '@store/api/steamhub/resource.api.graphql'
import { dashboardApiGraphql } from '@store/api/steamhub/dashboard.api.graphql'
import { pageApiGraphql } from '@store/api/steamhub/page.api.graphql'
import { searchApiGraphql } from '@store/api/steamhub/search.api.graphql' // todo revise
import { roomApiGraphql } from '@store/api/steamhub/room.api.graphql'
import { sectionApiGraphql } from '@store/api/steamhub/section.api.graphql'

const isSSR = typeof window === 'undefined'
const isDev = process.env.NODE_ENV !== 'production'

// todo extract to separate file
const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  nav: navReducer,

  [featureApi.reducerPath]: featureApi.reducer,
  [featureApiGraphql.reducerPath]: featureApiGraphql.reducer,

  [userApiGraphql.reducerPath]: userApiGraphql.reducer,

  [authApi.reducerPath]: authApi.reducer, // todo Move to user layer

  [libraryApiGraphql.reducerPath]: libraryApiGraphql.reducer,
  [projectApiGraphql.reducerPath]: projectApiGraphql.reducer,
  [lessonApiGraphql.reducerPath]: lessonApiGraphql.reducer,
  [activityApiGraphql.reducerPath]: activityApiGraphql.reducer,
  [resourceApiGraphql.reducerPath]: resourceApiGraphql.reducer,
  [dashboardApiGraphql.reducerPath]: dashboardApiGraphql.reducer,
  [pageApiGraphql.reducerPath]: pageApiGraphql.reducer,
  [searchApiGraphql.reducerPath]: searchApiGraphql.reducer, // todo revise
  [roomApiGraphql.reducerPath]: roomApiGraphql.reducer,
  [sectionApiGraphql.reducerPath]: sectionApiGraphql.reducer,
})

// todo extract to separate file
const middlewares = [
  featureApi.middleware,
  featureApiGraphql.middleware,

  userApiGraphql.middleware,
  authApi.middleware,// todo Move to user layer

  libraryApiGraphql.middleware,
  projectApiGraphql.middleware,
  lessonApiGraphql.middleware,
  activityApiGraphql.middleware,
  resourceApiGraphql.middleware,
  dashboardApiGraphql.middleware,
  pageApiGraphql.middleware,
  searchApiGraphql.middleware, // todo revise
  roomApiGraphql.middleware,
  sectionApiGraphql.middleware,
]

let reducer, store, persistor

if (isSSR) {
  // SSR Setup;
  reducer = rootReducer
} else {
  // CLIENT Setup
  const storage = new CookieStorage(Cookies, {
    setCookieOptions: {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      path: '/',
      httpOnly: true,
    },
  })

  const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['ui', 'auth'],
  }

  reducer = persistReducer(persistConfig, rootReducer)
}

store = configureStore({
  preloadedState: {},
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // thunk: true
    }).concat(middlewares),
  devTools: isDev && !isSSR,
})

if (!isSSR) {
  persistor = persistStore(store)
  setupListeners(store.dispatch)

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  // }
}

export { store, persistor }

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
