import { gql } from '@lib/store/strapi-graphql-query'

import { WITH_RELATED_GRADES } from '@store/fragments/grade'
import { WITH_RELATED_TAGS } from '@store/fragments/tag'
import { WITH_RELATED_SUBJECTS } from '@store/fragments/subject'
import { WITH_FILE } from '@store/fragments/file'
import { WITH_DURATION } from '@store/fragments/atoms/time-selector'

export const ROOMS_LIST_QUERY = gql`
    query GetRoomsList {
        rooms {
            data {
                id
                attributes {
                    name
                    slug
                    description
                    enabled
                    isTopLevel
                }
            }
        }
    }
`;


export const ROOM_QUERY = gql`
  ${WITH_DURATION}
  ${WITH_RELATED_SUBJECTS}
  ${WITH_RELATED_GRADES}
  ${WITH_RELATED_TAGS}
  ${WITH_FILE}
    
    query GetRoomData($slug: String!, $userFilters: JSON, $page: Int = 0) {
      roomEntry(slug: $slug, userFilters: $userFilters, page: $page)  {
          data {
              id
              name
              slug
              enabled
              
              enablePagination
              pageSize
              
              isTopLevel
              description
              showHeading

              projects {
                  data {
                      __typename
                      id
                      attributes {
                          name
                          slug
                          updatedAt
                          ordering

                          preview {
                              ...FILE
                          }

                          preparationDuration {
                              ...DURATION
                          }

                          learningDuration {
                              ...DURATION
                          }

                          subjects(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_SUBJECTS
                          }

                          grades(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_GRADES
                          }

                          tags {
                              ...RELATED_TAGS                            
                          }
                      }
                  }
              }

              lessons {
                  data {
                      __typename
                      id
                      attributes {
                          name
                          slug
                          updatedAt
                          ordering

                          preview {
                              ...FILE
                          }

                          preparationDuration {
                              ...DURATION
                          }

                          learningDuration {
                              ...DURATION
                          }

                          subjects(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_SUBJECTS
                          }

                          grades(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_GRADES
                          }

                          tags {
                              ...RELATED_TAGS
                          }
                      }
                  }
              }

              activities {
                  data {
                      __typename
                      id
                      attributes {
                          name
                          slug
                          updatedAt
                          ordering

                          preview {
                              ...FILE
                          }

                          preparationDuration {
                              ...DURATION
                          }

                          learningDuration {
                              ...DURATION
                          }

                          subjects(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_SUBJECTS
                          }

                          grades(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_GRADES
                          }

                          tags {
                              ...RELATED_TAGS
                          }
                      }
                  }
              }

              resources {
                  data {
                      __typename
                      id
                      attributes {
                          name
                          slug
                          updatedAt
                          ordering

                          preview {
                              ...FILE
                          }

                          subjects(sort: ["ordering:asc", "name:asc"]) {
                              ...RELATED_SUBJECTS
                          }

                          tags {
                              ...RELATED_TAGS
                          }
                      }
                  }
              }

              challenges {
                  data {
                      __typename
                      id
                  }
              }
          }
      }
  }
`

export const ROOM_FILTERS_QUERY = gql`
  query GetRoomFilters($slug: String!) {
    roomFilters(slug: $slug)  {
        data {
            id
            attributes {
                name
                slug
                enabled
                
                allowedFilters {
                    __typename
                    
                    # 1. CONTENT TYPE
                    ... on ComponentFiltersContentTypeFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                        
                        showProjects
                        showLessons
                        showActivities
                        showResources
                        showChallenges
                    }

                    # 2. DURATION
                    ... on ComponentFiltersDurationFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                        
                        durations {
                            id
                            title
                            fromHours
                            fromMinutes
                            toHours
                            toMinutes
                        }
                    }
                    
                    # 3. FAVORITE
                    ... on ComponentFiltersFavoriteFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                    }

                    # 4. GRADES
                    ... on ComponentFiltersGradesFilter {
                        id
                        enabled
                        editable
                        title
                        multiple

                        grades {
                            data {
                                id
                                attributes {
                                    name
                                    ordering
                                }
                            }
                        }
                    }
                    
                    # 5. COMPLEXITY
                    ... on ComponentFiltersComplexityFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                        
                        complexities {
                            data {
                                id
                                attributes {
                                    name
                                    ordering
                                }
                            }
                        }
                    }

                    # 6. OWNER
                    ... on ComponentFiltersOwnerFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                    }
                    
                    # 7. SUBJECTS
                    ... on ComponentFiltersSubjectsFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                        
                        subjects {
                            data {
                                id
                                attributes {
                                    name
                                    ordering
                                    code
                                }
                            }
                        }
                    }

                    # 8. SUBSCRIPTIONS
                    ... on ComponentFiltersSubscriptionsFilter {
                        id
                        enabled
                        editable
                        title
                        multiple

                        subscriptions {
                            data {
                                id
                                attributes {
                                    name
                                    publishedAt
                                }
                            }
                        }
                    }
                    
                    # 9. TAGS
                    ... on ComponentFiltersTagsFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                        
                        tags {
                            data {
                                id
                                attributes {
                                    name
                                    slug
                                }
                            }
                        }
                    }
                    
                    # 10. SHARED
                    ... on ComponentFiltersSharedFilter {
                        id
                        enabled
                        editable
                        title
                        multiple
                        
                        showSharedByMe
                        showSharedWithMe
                    }
                    
                }
    
            }
        }
    }
  }
`
