import { gql } from '@lib/store/strapi-graphql-query'

export const WITH_PROJECT_EVALUATION_SECTION = gql`
  fragment PROJECT_EVALUATION_SECTION on ComponentSectionsProjectEvaluationSection {
    title
    description
    enumerable
    content

    captions {
      ...LINKABLE_LIST
    }

    buttons {
      ...LINKABLE_LIST
    }

    preparationDuration {
      ...DURATION
    }

    learningDuration {
      ...DURATION
    }

    assets {
      files {
        ...FILES
      }
    }
  }
`
