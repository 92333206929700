import { gql } from '@lib/store/strapi-graphql-query'

export const USER_LOGIN_MUTATION = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt    

      user {
        id
        username
        blocked
        confirmed
        email

        role {          
          name
        }
      }     
    }
  }
`