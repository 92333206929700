import { createApi } from '@reduxjs/toolkit/query/react'
import protectedQuery from '@store/utils/protected-query'

export const featureApi = createApi({
  reducerPath: 'featureApi',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getFeatureById: builder.query({
      query: id => `/features/${id}`,
    }),
    getFeatureByName: builder.query({
      query: name => ({
        url: `features?filters[name][$eq]=${name}`,
      }),
    }),
    getFeatures: builder.query({
      query: () => 'features',
    }),
  }),
})

export const { useGetFeatureByIdQuery, useGetFeatureByNameQuery, useGetFeaturesQuery } = featureApi
