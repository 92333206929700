import React, { useLayoutEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'

import { CssBaseline } from '@mui/material'

import theme from '../themes/general.theme'

export default function Layout({ children, currentTheme = theme }) {
  // useLayoutEffect(() => {
  //   console.log("THEME", theme);
  // }, [currentTheme]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline enableColorScheme={true} />
        {children}
      </ThemeProvider>
    </>
  )
}
