import { createApi } from '@reduxjs/toolkit/query/react'
import protectedQuery from '@store/utils/protected-query.graphql'

import { FULL_DASHBOARD_QUERY } from '@store/queries/dashboard'

export const dashboardApiGraphql = createApi({
  reducerPath: 'dashboardApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getDashboardData: builder.query({
      query: () => ({
        document: FULL_DASHBOARD_QUERY
      }),
    }),
  }),
})

export const { useGetDashboardDataQuery } = dashboardApiGraphql
