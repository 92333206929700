import { createApi } from '@reduxjs/toolkit/query/react'

import protectedQuery from '@store/utils/protected-query.graphql'

import { ROOM_QUERY, ROOM_FILTERS_QUERY, ROOMS_LIST_QUERY } from '@store/queries/room'

export const roomApiGraphql = createApi({
  reducerPath: 'roomApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getRoomsList: builder.query({
      query: () => ({
        document: ROOMS_LIST_QUERY,
      }),
    }),

    getRoomData: builder.query({ 
      query: (body : { slug: String, userFilters: {} }) => {
        return {
          document: ROOM_QUERY,
          variables: { slug: body.slug, userFilters: body.userFilters},
        }
      },
    }),

    getRoomFilters: builder.query({
      query: (slug: String) => ({
        document: ROOM_FILTERS_QUERY,
        variables: { slug },
      }),
    }),
  }),
})

export const { useGetRoomsListQuery, useGetRoomDataQuery, useGetRoomFiltersQuery } = roomApiGraphql
