import { overrides, typography } from '../config/theme-typography'
import { colors as themeColors } from '../config/theme-colors'
import { ThemeOptions } from '@mui/system'

/**
 *
 * @type {ThemeOptions}
 */

export const MuiButtonOverrides = {
  styleOverrides: {
    // common settings
    root: {
      borderRadius: '8px',
      textTransform: 'none',
      paddingLeft: '32px',
      paddingRight: '32px',
    },

    containedPrimary: {
      background: themeColors.violet.main,
      color: themeColors.white.main,

      '&.Mui-disabled': {
        background: themeColors.violet['100'],
        color: themeColors.overlays.white40,
      },

      '&:hover, &.Mui-focusVisible': {
        background: themeColors.violet['300'],
      },

      '&:active, &.Mui-active': {
        background: themeColors.violet['700'],
        color: themeColors.overlays.white60,
      },
    },

    outlined: {
      borderWidth: '2px !important',
    },

    outlinedPrimary: {
      background: themeColors.white.main,
      borderColor: themeColors.violet.main,
      color: themeColors.violet.main,

      '&.Mui-disabled': {
        background: themeColors.white.main,
        borderColor: themeColors.violet['100'],
        color: themeColors.violet['100'],
      },

      '&:hover, &.Mui-focusVisible': {
        background: themeColors.secondary.whiteSmoke,
        borderColor: themeColors.violet['700'],
      },

      '&:active, &.Mui-active': {
        background: themeColors.black['100'],
        borderColor: themeColors.violet['700'],
        color: themeColors.violet['700'],
      },
    },

    textPrimary: {
      background: 'none',
      color: themeColors.violet.main,

      '&.Mui-disabled': {
        background: 'none',
        color: themeColors.violet['100'],
      },

      '&:hover, &.Mui-focusVisible': {
        background: themeColors.secondary.whiteSmoke,
        color: themeColors.violet['700'],
      },

      '&:active, &.Mui-active': {
        background: themeColors.black['100'],
        color: themeColors.violet['700'],
      },
    },

    // sizes
    sizeExtraLarge: {
      ...overrides.extra['x-button1-bold'],
      height: '64px',
    },
    sizeLarge: {
      ...overrides.extra['x-button1-bold'],
      height: '48px',
    },
    sizeMedium: {
      ...overrides.extra['x-button2-bold'],
      height: '40px',
    },
    sizeSmall: {
      ...overrides.extra['x-button3-bold'],
      height: '36px',
    },
  },
}
