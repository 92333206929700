import { ThemeOptions } from '@mui/system'

/**
 *
 * @type {ThemeOptions}
 */

export const MuiSelectOverrides = {
  styleOverrides: {
    root: {
      fontSize: '16px',
      borderRadius: '8px',
      height: '40px',
      lineHeight: '40px',

      // disable default border
      '&.MuiInputBase-root': {
        transition: 'none',

        '& .MuiSelect-select': {
          paddingTop: 0,
          paddingBottom: 0,
          height: '100%',
        },
      },
      '&.MuiInputBase-root:before, &.MuiInputBase-root:after': {
        borderBottom: 'none !important',
        transition: 'none',
      },
    },
  },

  variants: [
    // sizes
    {
      props: { size: 'extraLarge' },
      style: {
        height: '64px',
        lineHeight: '64px',
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: '48px',
        lineHeight: '48px',
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: '40px',
        lineHeight: '40px',
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: '36px',
        lineHeight: '36px',
      },
    },
  ],
}
