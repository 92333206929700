import { gql } from '@lib/store/strapi-graphql-query'

export const WITH_CHALLENGES_OVERVIEW = gql`
  fragment CHALLENGES_OVERVIEW on ChallengeRelationResponseCollection {
    data {
      id
        __typename
      attributes {
        name
      }
    }
  }
`
