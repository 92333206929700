import { ThemeOptions } from '@mui/system'
import { colors as themeColors } from '@themes/config/theme-colors'

/**
 *
 * @type {ThemeOptions}
 */

export const MuiPaperOverrides = {
  variants: [
    {
      props: { variant: 'filled' },
      style: {
        backgroundColor: 'rgba(39, 39, 39, 0.05)',
        boxShadow: 'none !important',
      },
    },
  ],
  styleOverrides: {
    root: {
      borderRadius: '20px',
      color: themeColors.black['500'],
    },
  },
}
