import { gql } from '@lib/store/strapi-graphql-query'

export const WITH_ACTIVITIES_OVERVIEW = gql`
  fragment ACTIVITIES_OVERVIEW on ActivityRelationResponseCollection {
    data {
      id
        __typename
      attributes {
        name

        preview {
          ...FILE
        }
        preparationDuration {
          ...DURATION
        }
        learningDuration {
          ...DURATION
        }
      }
    }
  }
`
