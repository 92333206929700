import { createSlice } from '@reduxjs/toolkit'
import { store } from '../configureStore'

const initialState = {
  theme: 'light',
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const { payload } = action
      state.theme = payload
    },
  },
})

// reducers
export default uiSlice.reducer

// selectors
export const uiSelector = state => state.ui

// actions
export const { setTheme } = uiSlice.actions

// thunks
export const toggleTheme = () => dispatch => {
  const { theme } = store.getState().ui
  const newTheme = theme === 'light' ? 'dark' : 'light'
  dispatch(setTheme(newTheme))
}
