import { fetchBaseQuery } from '@reduxjs/toolkit/query'

export const strapiRestQuery =
  ({ baseUrl, prepareHeaders, ...restOptions }) =>
  async (args, opts, extraOptions = {}) => {
    try {
      const result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders,
        ...restOptions,
      })(args, opts, extraOptions)

      return processResponse(result)
    } catch (error) {
      return processError(error)
    }
  }

const processResponse = response => {
  const processedResponse = {} as any

  if (!response) {
    return { error: { status: 400, data: { message: 'Malformed response' } } }
  }

  // store and return error
  if (response.error) {
    // store error meta
    if (response.meta) {
      processedResponse.meta = response.meta
    }

    processedResponse.error = {
      status: response.error.status || 400,
      data: { message: response.error.data?.error?.message || response.error.message || 'Unexpected error' },
    }

    return processedResponse
  }

  processedResponse.data = response.data.data || response.data
  processedResponse.meta = response.data.meta || response.meta

  return processedResponse
}

const processError = error => {
  return {
    error: {
      status: error.status || 500,
      data: { message: error.message || 'Unexpected error' },
    },
  }
}
