import { request, gql as gqlClient, ClientError } from 'graphql-request'

import { store } from '@store/configureStore'
import { clearCredentials } from '@store/slices/auth.slice'

export const strapiGraphqlQuery =
  ({ baseUrl, prepareHeaders }) =>
    async (args, opts, extraOptions = {}) => {
      const { getState } = opts
      const headers = new Headers()

      if (typeof prepareHeaders === 'function') {
        prepareHeaders(headers, { getState })
      }

      try {
        const result = await request({
          url: baseUrl,
          requestHeaders: headers,
          ...args
        })


        // return processResponse(result)
        return { data: result }
      } catch (error) {
        return processError(error)
      }
    }

const processResponse = response => {
  let data
  let error
  let meta
  let firstElement

  if (!response) {
    return { error: { status: 400, data: { message: 'Malformed response' } } }
  }

  if (response.search) {
    return { data: response }
  }

  // retrieve first element's data and meta fields from the response
  firstElement = response[Object.keys(response)[0]]

  // if first element is empty - create not found error
  if (!firstElement) {
    return { error: { status: 404, data: { message: 'Not found' } } }
  }

  data = response
  meta = response.meta || firstElement.meta
  error = response.error || firstElement.error

  return { data, error, meta }
}

const processError = error => {

  if (error.response?.status === 401) {
    store.dispatch(clearCredentials());
  }

  if (error instanceof ClientError) {
    return {
      error: {
        status: error.response?.status || 400,
        data: { message: error.response?.error?.message || 'Client Error' },
      },
    }
  }

  return {
    error: {
      status: error.status || 500,
      data: { message: error.message || 'Unexpected error' },
    },
  }
}

export const gql = gqlClient
