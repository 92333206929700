import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../configureStore'

type NavState = {
  app: string
  page: string
  subPage: string
}

const navSlice = createSlice({
  name: 'nav',
  initialState: {
    app: 'steamhub',
    page: 'profile',
    subPage: 'main',
  } as NavState,

  reducers: {
    setApp: (state, { payload: { app } }: PayloadAction<{ app: string }>) => {
      state.app = app
    },
    setPage: (state, { payload: { page } }: PayloadAction<{ page: string }>) => {
      state.page = page
    },
    setSubPage: (state, { payload: { subPage } }: PayloadAction<{ subPage: string }>) => {
      state.subPage = subPage
    },
  },
})

export const { setApp, setPage, setSubPage } = navSlice.actions

export default navSlice.reducer

export const selectNav = (state: RootState) => state.nav
export const selectApp = (state: RootState) => state.nav.app
export const selectPage = (state: RootState) => state.nav.page
export const selectSubPage = (state: RootState) => state.nav.subPage
