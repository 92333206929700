import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../configureStore'

export default ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<h2>Loading...</h2>}>
        {element}
      </PersistGate>
    </Provider>
  )
}
