require('@fontsource/raleway/400.css')
require('@fontsource/raleway/600.css')
require('@fontsource/raleway/700.css')
require('@fontsource/raleway/900.css')

import Layout from '@layouts/layout'

import wrapWithProvider from '@store/utils/wrap-with-provider'

export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
