import { gql } from '@lib/store/strapi-graphql-query'

export const FULL_SECTIONS_QUERY = gql`
    query GetSections($filters: SectionFiltersInput) {
        sections(filters: $filters) {
            data {
                id
                attributes {
                    name
                    enabled
                    externalId

                    parentSection {
                        data {
                            id
                            attributes {
                                name
                                externalId                        
                            }
                        }
                    }

                    students {
                        data {
                            id
                            attributes {                            
                                name
                                externalId
                                email
                                username                             
                            }
                        }
                    }

                    teachers {
                        data {
                            id
                            attributes {
                                name
                                externalId
                                email
                                username
                            }
                        }
                    }

                    grades {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }

                    subjects {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }

                    owner {
                        data {
                            id
                            attributes {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`