import { strapiGraphqlQuery } from '@lib/store/strapi-graphql-query'

const API_URL = process.env.GATSBY_API_URL + '/graphql'

export default strapiGraphqlQuery({
  baseUrl: API_URL,

  prepareHeaders: (headers: Headers, { getState }) => {
    const { auth } = getState()
    const { jwt } = auth

    if (jwt) {
      headers.set('Authorization', `Bearer ${jwt}`)
    }

    return headers
  },
})
